import type { NextPage } from 'next'

import Login from './login/index'

const Index: NextPage = () => {
  return (
    <>
      <Login />
    </>
  )
}

export default Index
